<template xmlns:wire="http://www.w3.org/1999/xhtml">
  <div class="">
    <!--  NoteEdit 履歷筆記編輯設置跳窗(可選img or content編輯  -->
    <div v-if="showEditNoteDialog" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-700 opacity-95">
      <div class="flex flex-col p-5 rounded-lg shadow bg-white border-2 border-gray-100 opacity-100 px-10">
        <div class="flex flex-col items-center text-center">
          <h2 class="mt-1 text-3xl font-semibold text-o_purple-100 leading-relaxed">履歷紀錄筆記編輯更新</h2>
        </div>
        <div class="flex flex-col items-start text-center mb-2">
          <h2 class="text-2xl font-semibold text-o_purple-100 leading-relaxed">筆記紀錄ID為: {{currentNoteRecordID}}</h2>
        </div>
        <div>
          <label class="text-xl block text-o_purple-200 text-sm font-bold mb-1">可於下選擇要更新圖片或文字</label>
        </div>
        <button @click="showImageEditDialog=true, showEditNoteDialog=false" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
          履歷圖片內容編輯更新
        </button>
        <button @click="showContentEditDialog=true, showEditNoteDialog=false" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
          履歷文字內容編輯更新
        </button>
        <button @click="showEditNoteDialog = !showEditNoteDialog" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-white border-2 border-o_purple-200 hover:bg-gray-50 text-o_purple-200 text-sm font-medium rounded-md">
          取消
        </button>
      </div>
    </div>
    <!--  NoteImageEdit 圖片筆記上載設置跳窗  -->
    <!--  這個若取消退回來，之前選的圖片設置會清空，不像字串可以保留，因為這部分是瀏覽器在操作，沒有特定屬性可以綁定(不然的話就能以v-show方式，不會刷新，這邊既然無法保存，就採v-if刷新)   -->
    <div v-if="showImageEditDialog" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-700 opacity-95">
      <div class="flex flex-col p-5 rounded-lg shadow bg-white border-2 border-gray-100 opacity-100 px-10">
        <div class="flex flex-col items-center text-center">
          <h2 class="mt-1 text-3xl font-semibold text-o_purple-100 leading-relaxed">履歷紀錄圖片上傳更新</h2>
        </div>
        <div class="flex flex-col items-start text-center mb-1">
          <h2 class="text-2xl font-semibold text-o_purple-100 leading-relaxed">筆記紀錄ID:{{currentNoteRecordID}} 圖片筆記</h2>
        </div>
        <div>
<!--          <label class="text-xl block text-o_purple-200 text-sm font-bold mb-2">既有筆記圖片狀態:</label>-->
          <img class="h-72 w-full border-2 mb-2 rounded-xl shadow" :src="currentImgURL" alt="">
        </div>
        <div>
          <label class="text-xl block text-o_purple-100 text-sm font-bold mb-2" for="img_input">上傳檔案須為.jpg格式</label>
          <label class="text-sm block text-o_purple-200 text-sm font-bold mb-2">註: 刪除-清除既有筆記圖片，更新-覆蓋既有筆記圖片依所選圖片</label>
          <input class="text-xl text-o_purple-100 rounded-xl shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="img_input" type="file" accept=".jpg" @change="handleImgUpload">
        </div>
        <div class="flex flex-row mt-1 mb-2 grid grid-cols-3 gap-3">
          <button @click="showImageEditDialog = !showImageEditDialog, showEditNoteDialog=true, selectedImg=null" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-white border-2 border-o_purple-200 hover:bg-gray-50 text-o_purple-200 text-sm font-medium rounded-md">
            取消
          </button>
          <button @click="deleteNoteJpgImgDateById" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
            刪除
          </button>
          <button @click="uploadNoteJpgImgData" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
            更新
          </button>
        </div>
      </div>
    </div>
    <!--  NoteContentEdit 文字筆記上載設置跳窗  -->
    <div v-if="showContentEditDialog" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-700 opacity-95">
      <div class="flex flex-col p-5 rounded-lg shadow bg-white border-2 border-gray-100 opacity-100 px-10">
        <div class="flex flex-col items-center text-center">
          <h2 class="mt-1 text-3xl font-semibold text-o_purple-100 leading-relaxed">履歷紀錄文字上傳更新</h2>
        </div>
        <div class="flex flex-col items-start text-center">
          <h2 class="text-2xl font-semibold text-o_purple-100 leading-relaxed">筆記紀錄ID:{{currentNoteRecordID}} 文字筆記</h2>
        </div>
        <div>
<!--          <div class="flex flex-col items-start text-center mb-1">-->
<!--            <label class="text-xl block text-o_purple-200 text-sm font-bold mb-2">既有筆記文字狀態:</label>-->
<!--          </div>-->
          <p class="text-xl text-o_purple-100 rounded-xl shadow appearance-none border w-96 h-32 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2 overflow-auto" id="note" rows="5" required>{{ currentNoteContent }}</p>
        </div>
        <div>
          <label class="text-xl block text-o_purple-100 text-sm font-bold mb-2" for="content_input">於下輸入欲更新文字(會覆蓋舊的文字)</label>
          <label class="text-sm block text-o_purple-200 text-sm font-bold mb-2">註: 刪除-清除既有筆記文字，更新-覆蓋既有筆記文字依輸入內容</label>
          <textarea v-model="setNoteContent" class="text-xl text-o_purple-100 rounded-xl shadow appearance-none border rounded w-96 h-32 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline " id="content_input" rows="5" required></textarea>
        </div>
        <div class="flex flex-row mt-1 mb-2 grid grid-cols-3 gap-3">
          <button @click="showContentEditDialog = !showContentEditDialog, showEditNoteDialog=true, setNoteContent=''" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-white border-2 border-o_purple-200 hover:bg-gray-50 text-o_purple-200 text-sm font-medium rounded-md">
            取消
          </button>
          <button @click="deleteNoteTextContentDateById" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
            刪除
          </button>
          <button @click="uploadNoteTextContentData" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
            更新
          </button>
        </div>
      </div>
    </div>
    <!--  Note:筆記圖片文字顯示跳窗  -->
    <div v-if="showNoteDialog" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-700 opacity-95">
      <div class="custom-note-dialog-h tableScrollbar flex flex-col pt-1 p-5 rounded-lg shadow bg-white border-2 border-gray-100 opacity-100 px-10 overflow-auto">
        <div class="flex flex-col items-center text-center">
          <h2 class="mt-1 text-3xl text-left font-semibold text-o_purple-100 leading-relaxed">履歷筆記</h2>
        </div>
        <div class="flex flex-col items-start text-center mb-1">
          <h2 class="mt-1 text-2xl font-semibold text-o_purple-100 leading-relaxed">筆記紀錄ID為: {{currentNoteRecordID}}</h2>
        </div>
        <div class="flex flex-row mt-0.5 mb-2 grid grid-cols-2 gap-3">
          <button @click="showNoteDialog = !showNoteDialog" class="text-xl font-semibold col-span-2 px-4 py-1.5 mt-1 bg-white border-2 border-o_purple-200 hover:bg-gray-50 text-o_purple-200 text-sm font-medium rounded-md">
            關閉
          </button>
        </div>
        <img class="h-96" :src="currentImgURL" alt="">
        <div>
          <div class="flex flex-col items-start text-center mb-1">
            <h2 class="mt-1 text-2xl font-semibold text-o_purple-100 leading-relaxed">筆記內容</h2>
          </div>
          <p class="text-xl text-o_purple-100 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="note" rows="5" required>{{ currentNoteContent }}</p>
        </div>
      </div>
    </div>
    <!--  碳計算確認計算跳窗  -->
    <div v-if="showCarbonComputeDialogToDB" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-700 opacity-95">
      <div class="flex flex-col p-5 rounded-lg shadow bg-white border-2 border-gray-100 opacity-100 px-10">
        <div class="flex flex-col items-center text-center mb-2">
          <h2 class="mt-1 text-3xl font-semibold text-o_purple-100 leading-relaxed">針對選取產品數值碳計算</h2>
          <p class="mt-2 text-xl font-semibold text-o_purple-100 leading-relaxed">
            {{ readSelectState }}
          </p>
        </div>
        <div class="flex flex-row mt-1 mb-2 grid grid-cols-2 gap-3">
          <button @click="showCarbonComputeDialogToDB = !showCarbonComputeDialogToDB" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-white border-2 border-o_purple-200 hover:bg-gray-50 text-o_purple-200 text-sm font-medium rounded-md">
            取消
          </button>
          <button @click="downLoadExcelDataWithGPT" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
            計算
          </button>
        </div>
      </div>
    </div>
    <!--  客製索引取得履歷Excel設置跳窗  -->
    <div v-if="showSetQueryDialogToDB" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-700 opacity-95">
      <div class="flex flex-col p-5 rounded-lg shadow bg-white border-2 border-gray-100 opacity-100 px-10">
        <div class="flex flex-col items-center text-center mb-2">
          <h2 class="mt-1 text-3xl font-semibold text-o_purple-100 leading-relaxed">客製索引取得履歷Excel</h2>
        </div>
        <div>
          <label class="text-2xl block text-o_purple-200 text-sm font-bold mb-2" for="custom_query">取得條件</label>
          <textarea v-model="setQueryContent" class="text-xl text-o_purple-100 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="custom_query" rows="5" required></textarea>
        </div>
        <div class="flex flex-row mt-1 mb-2 grid grid-cols-2 gap-3">
          <button @click="showSetQueryDialogToDB = !showSetQueryDialogToDB" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-white border-2 border-o_purple-200 hover:bg-gray-50 text-o_purple-200 text-sm font-medium rounded-md">
            取消
          </button>
          <button @click="downLoadExcelDataWithGPT" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
            索引
          </button>
        </div>
      </div>
    </div>
    <!--  批次檔案設置跳窗  -->
    <div v-if="showUploadExcelDialogToDB" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-700 opacity-95">
      <div class="flex flex-col p-5 rounded-lg shadow bg-white border-2 border-gray-100 opacity-100 px-10">
        <div class="flex flex-col items-center text-center mb-2">
          <h2 class="mt-1 text-3xl font-semibold text-o_purple-100 leading-relaxed">批次Excel履歷紀錄上傳</h2>
        </div>
        <div>
          <label class="text-2xl block text-o_purple-200 text-sm font-bold mb-2" for="file_input">上傳檔案必須為.xlsx檔案</label>
          <input class="text-xl text-o_purple-100 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="file_input" type="file" accept=".xlsx" @change="handleFileUpload">
        </div>
        <div class="flex flex-row mt-1 mb-2 grid grid-cols-2 gap-3">
          <button @click="showUploadExcelDialogToDB = !showUploadExcelDialogToDB" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-white border-2 border-o_purple-200 hover:bg-gray-50 text-o_purple-200 text-sm font-medium rounded-md">
            取消
          </button>
          <button @click="uploadExcelData" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
            上傳
          </button>
        </div>
      </div>
    </div>
    <!--  提醒廣播設置跳窗  -->
    <div v-if="showNotificationDialog" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-700 opacity-95">
      <div class="w-3/5 flex flex-col p-5 rounded-lg shadow bg-white border-2 border-gray-100 opacity-100">
        <div class="flex flex-col items-center text-center">
          <div class="inline-block p-4 bg-o_purple-100 rounded-full">
            <svg class="w-12 h-12 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"/></svg>
          </div>
          <h2 class="mt-2 text-xl font-semibold text-gray-800">提示訊息</h2>
          <p class="mt-2 text-xl font-semibold text-o_purple-100 leading-relaxed">
            {{ formattedMessage }}
          </p>
        </div>

        <div class="flex items-center mt-3">
          <button @click="showNotificationDialog = !showNotificationDialog" class="flex-1 px-4 py-2 mt-2 mx-10 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
            確定
          </button>
        </div>
      </div>
    </div>
    <!--  泛用操作確認執行Dialog  -->
    <div v-if="showGeneralConfirmDialog" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-700 opacity-95">
      <div class="flex flex-col p-5 rounded-lg shadow bg-white border-2 border-gray-100 opacity-100">
        <div class="flex flex-col items-center text-center">
          <div class="inline-block p-4 bg-o_purple-100 rounded-full">
            <svg class="w-12 h-12 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"/></svg>
          </div>
          <h2 class="mt-3 text-xl block text-o_purple-200 text-sm font-bold mb-2">{{ generalConfirmTitle }}</h2>
        </div>

        <div class="flex flex-row mt-1 mb-2 grid grid-cols-2 gap-3">
          <button @click="checkGeneralConfirmState(false)" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-white border-2 border-o_purple-200 hover:bg-gray-50 text-o_purple-200 text-sm font-medium rounded-md">
            取消
          </button>
          <button @click="checkGeneralConfirmState(true)" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
            確定
          </button>
        </div>
      </div>
    </div>
    <!---------------------->
    <!------- 管理選單 ------->
    <div v-show="switchManageAndReadState" class="bg-gray-50 sticky top-0 z-20">
      <div class="sticky top-0 bg-gray-50 border-b pb-1.5 z-20">
        <div class="flex flex-row items-center ml-4">
          <label id="listbox-label1" class="fixed block relative text-2xl text-o_purple-100 font-semibold mr-2">
            紀錄選項:
          </label>
          <div class="relative inline-block text-left">
            <div>
              <button v-on:click="filterActive = !filterActive" type="button"
                      class="text-o_purple-100 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-0.5 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
                {{ selectFilterState }}
                <!-- Heroicon name: solid/chevron-down -->
                <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
              <!--
                Dropdown panel, show/hide based on dropdown state.

                Entering: "transition ease-out duration-100"
                  From: "transform opacity-0 scale-95"
                  To: "transform opacity-100 scale-100"
                Leaving: "transition ease-in duration-75"
                  From: "transform opacity-100 scale-100"
                  To: "transform opacity-0 scale-95"
              -->
              <div v-show="filterActive" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <!-- 選單滑動要設定最大高度max-h-64(先以64px)，再設置overflow-y-auto，才會產生滾輪選單，最後設置tableScrollbar設置滾輪樣式 -->
                <div class="tableScrollbar py-1 max-h-64 overflow-y-auto" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <a @click.prevent="selectFilterEvent"
                     v-for="filter in filterList" :key="filter"
                     href="#" class="block px-3 py-0.5 text-xl text-o_purple-100 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    {{ filter }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <button
            @click="checkConnection"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            連線確認
          </button>
          <button
            @click = "getRecordFromAPP"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            取得紀錄
          </button>
          <button
            @click = "reloadPage"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            重新整理
          </button>
          <button
            @click="commitVerify"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            紀錄上鏈
          </button>
          <button
            @click = "reCheckPage"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            驗證確認
          </button>
          <button
            @click = "downLoadExcelData"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            下載紀錄
          </button>
          <button
            @click = "showUploadExcelDialog"
            ref="fileInput"
            accept=".xlsx"
            type="file"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            批次匯入
          </button>
          <button
            @click = "switchManageAndReadState=false, currentMangeContentTemp=contents, contents=currentReadContentTemp"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            閱覽
          </button>
        </div>
      </div>
      <div class="flex p-1 px-4 border-b">
        <!-- 下面這個是測試看是否有抓到資料     -->
        <!--      <h1>Current message: ID 1 is edited successfully</h1>-->
        <!--      <p>{{ content }}</p>-->
        <label id="listbox-label1" class="block text-xl text-o_purple-100 font-semibold mr-2">
          區塊鏈網絡連線狀態:
        </label>
        <span v-if="connected" class="px-4 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
        未連線
      </span>
        <span v-if="!connected" class="px-4 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
        連線中
      </span>
        <label id="listbox-label1" class="block text-xl text-o_purple-100 font-semibold ml-3 mr-2">
          存放順序閱覽:
        </label>
        <div class="flex rounded-md shadow-sm h-7">
          <!--        <div class="relative flex flex-grow items-stretch focus-within:z-10">-->
          <!--          <input type="text" v-model="recordLocationNumber" name="email" id="email" class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" :placeholder="`可輸入正整數1至${currentRecordPage}`" />-->
          <!--        </div>-->
          <label id="listbox-label1" class="block text-xl text-o_purple-100 font-semibold mr-2">
            於{{ currentRecordPage }}頁/共{{ recordMaxPage }}頁
          </label>
          <button type="button" @click="specifyRecordLocation(0)" class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-l-md px-1.5 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            首頁
          </button>
          <button type="button" @click="specifyRecordLocation(-1)" class="relative -ml-px inline-flex items-center gap-x-1.5 px-1.5 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            上頁
          </button>
          <button type="button" @click="specifyRecordLocation(1)" class="relative -ml-px inline-flex items-center gap-x-1.5 px-1.5 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            下頁
          </button>
          <button type="button" @click="specifyRecordLocation(recordMaxPage)" class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-1.5 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            尾頁
          </button>
          <button
            @click = "sortSwitch(1)"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-2 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            序號排序
          </button>
          <button
            @click = "sortSwitch(2)"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-2 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            日期排序
          </button>
        </div>

        <!--      <button-->
        <!--        @click = "sortSwitch(2)"-->
        <!--        type="button"-->
        <!--        class=" ml-3 font-semibold-->
        <!--                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md-->
        <!--                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2-->
        <!--                focus:ring-offset-2 focus:ring-o_purple-100">-->
        <!--        指定起始-->
        <!--      </button>-->
      </div>
    </div>
    <!---------------------->
    <!------- 閱覽選單 ------->
    <div v-show="!switchManageAndReadState" class="bg-gray-50 sticky top-0 z-20">
      <div class="sticky top-0 bg-gray-50 border-b pb-1.5 z-20">
        <div class="flex flex-row items-center ml-4">
          <label id="listbox-label1" class="fixed block relative text-2xl text-o_purple-100 font-semibold mr-2">
            產品選項:
          </label>
          <div class="relative inline-block text-left">
            <div>
              <button v-on:click="readActive = !readActive" type="button"
                      class="text-o_purple-100 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-0.5 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
                {{ readSelectState }}
                <!-- Heroicon name: solid/chevron-down -->
                <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
              <!--
                Dropdown panel, show/hide based on dropdown state.

                Entering: "transition ease-out duration-100"
                  From: "transform opacity-0 scale-95"
                  To: "transform opacity-100 scale-100"
                Leaving: "transition ease-in duration-75"
                  From: "transform opacity-100 scale-100"
                  To: "transform opacity-0 scale-95"
              -->
              <div v-show="readActive" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <!-- 選單滑動要設定最大高度max-h-64(先以64px)，再設置overflow-y-auto，才會產生滾輪選單，最後設置tableScrollbar設置滾輪樣式 -->
                <div class="py-1 max-h-64 overflow-y-auto tableScrollbar" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <a @click.prevent="readSelectEvent"
                     v-for="readoption in readSelectOptions" :key="readoption"
                     href="#" class="block px-3 py-0.5 text-xl text-o_purple-100 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    {{ readoption }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <button
            @click = "sortSwitch(1)"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-2 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            序號排序
          </button>
          <button
            @click = "sortSwitch(2)"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-2 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            日期排序
          </button>
          <button
            v-if="reloadReadPageActive"
            @click = "reloadReadPage()"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            重新整理
          </button>
          <button
            v-if="downloadReadPageActive"
            @click = "downLoadExcelDataWithProduct(readSelectState)"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            下載
          </button>
<!--          <button-->
<!--            v-if="carbonComputeActive"-->
<!--            @click = "showCarbonComputeDialogToDB = !showCarbonComputeDialogToDB"-->
<!--            type="button"-->
<!--            class=" ml-3 font-semibold-->
<!--                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md-->
<!--                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2-->
<!--                focus:ring-offset-2 focus:ring-o_purple-100">-->
<!--            碳計算-->
<!--          </button>-->
          <!-- 暫時關閉，留待下期 -->
<!--          <button-->
<!--            @click = "showSetQueryDialogToDB=true"-->
<!--            type="button"-->
<!--            class=" ml-3 font-semibold-->
<!--                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md-->
<!--                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2-->
<!--                focus:ring-offset-2 focus:ring-o_purple-100">-->
<!--            查詢-->
<!--          </button>-->
          <button
            @click = "switchManageAndReadState=true, contents=currentMangeContentTemp"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            管理
          </button>
        </div>
      </div>
    </div>
    <!---------------------->
    <div v-show="isLoading" wire:loading class="z-50 fixed top-0 left-0 right-0 bottom-0 w-full h-screen overflow-hidden bg-gray-700 opacity-80 flex flex-col items-center justify-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
      <h2 class="text-center text-white text-xl font-semibold">處理中...</h2>
      <p class="w-1/3 text-center text-white">這需要一些時間, 請不要關閉此頁面或做其他操作</p>
    </div>
    <form v-if="commitVerifyForm" @submit.prevent="commitRecords" class="space-y-8 divide-y divide-gray-200 fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-95 flex flex-col items-center justify-center">
      <div class="rounded-lg bg-white max-w-md rounded overflow-hidden shadow-xl p-5 opacity-100">
        <div class="mb-3">
          <!--        <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" />-->
          <h2 class="mt-1 text-3xl font-semibold text-o_purple-100 leading-relaxed text-center">
            將未驗證的紀錄送至區塊鏈
          </h2>
          <p class="mt-2 text-xl font-semibold text-o_purple-100 leading-relaxed text-center">
            上鏈操作會進行一段較長的時間，這段時間建議不要去對系統做其他操作與請求
          </p>
        </div>
        <div class="rounded-md shadow-sm -space-y-px">
          <div class="grid gap-6">
            <!-- 這邊可以設置可以修改的項目，我是預定id、time不能改以及state，能修改的地方就要幫他新增一個temp data，ex: emailTemp -->
<!--            <div class="col-span-12">-->
<!--              <label for="first_name" class="block text-sm font-medium text-gray-700">First name</label>-->
<!--              <input v-model="nameTemp" type="text" name="first_name" id="first_name" autocomplete="given-name" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-50 sm:text-sm">-->
<!--            </div>-->
<!--            <div class="col-span-12">-->
<!--              <label for="email_address" class="block text-sm font-medium text-gray-700">Email address</label>-->
<!--              <input v-model="emailTemp" type="text" name="email_address" id="email_address" autocomplete="email" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-50 sm:text-sm">-->
<!--            </div>-->

            <div class="flex flex-row mt-1 mb-2 grid grid-cols-2 gap-3">
              <button @click="cancelCommitVerify" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-white border-2 border-o_purple-200 hover:bg-gray-50 text-o_purple-200 text-sm font-medium rounded-md">
                取消
              </button>
              <button type="submit" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
                發送
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <form v-if="editForm" @submit.prevent="submitEdit" class="space-y-8 divide-y divide-gray-200 fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-80 flex flex-col items-center justify-center">
      <div class="rounded bg-white max-w-md rounded overflow-hidden shadow-xl p-5 opacity-100">
        <div class="mb-5">
          <!--        <img class="mx-auto h-12 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" />-->
          <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Change the record
          </h2>
        </div>
        <div class="rounded-md shadow-sm -space-y-px">
          <div class="grid gap-6">
            <!-- 這邊可以設置可以修改的項目，我是預定id、time不能改以及state，能修改的地方就要幫他新增一個temp data，ex: emailTemp -->
            <div class="col-span-12">
              <label for="first_name" class="block text-sm font-medium text-gray-700">First name</label>
              <input v-model="nameTemp" type="text" name="first_name" id="first_name" autocomplete="given-name" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-50 sm:text-sm">
            </div>
            <div class="col-span-12">
              <label for="email_address" class="block text-sm font-medium text-gray-700">Email address</label>
              <input v-model="emailTemp" type="text" name="email_address" id="email_address" autocomplete="email" class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-50 sm:text-sm">
            </div>

            <div class="flex">
              <button @click="cancelEdit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Cancel
              </button>
              <button type="submit" class="ml-1.5 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="tableScrollbar mx-3 mt-1 custom-table-inside overflow-x-scroll overflow-y-scroll flex flex-col bg-white shadow-2xl rounded-2xl">
      <div class="sm:-mx-6 lg:-mx-8 rounded-2xl">
        <div class="py-0 align-middle inline-block min-w-full sm:pl-6 lg:pl-8">
          <div class="shadow border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-o_purple-100 sticky top-0 z-10">
              <tr>
                <!--  履歷筆記編輯更新按鈕: 留給下期，暫時註解 -->
<!--                <th v-show="!switchManageAndReadState" scope="col" class="relative px-6  py-3">-->
<!--                  <span class="sr-only">NoteEdit</span>-->
<!--                </th>-->
                <!--  履歷筆記閱覽按鈕: 留給下期，暫時註解  -->
<!--                <th v-show="!switchManageAndReadState" scope="col" class="relative px-6  py-3">-->
<!--                  <span class="sr-only">NoteRead</span>-->
<!--                </th>-->
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Id
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  uuid
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  時間
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  地點
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  操作
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  細節
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  產品
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  產品批號
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  值
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  單位
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  面積
                </th>
                <th scope="col" class="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  面積單位
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  驗證
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  註銷
                </th>
                <th v-show="switchManageAndReadState" scope="col" class="relative px-4 py-3">
                  <span class="sr-only">註銷</span>
                </th>
              </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(content, contentIndex) in contentsFilterAndSortedContentsByUnixDate" :key="content.record_id">
                <!--  履歷筆記編輯更新按鈕: 留給下期，暫時註解 -->
<!--                <td v-show="!switchManageAndReadState" class="px-4 pr-1.5 py-4 whitespace-nowrap text-right text-sm font-medium">-->
<!--                  <a @click="showEditNote(content.record_id, content.has_content_or_img)" v-if="true" href="#" class="text-indigo-600 hover:text-indigo-900 text-2xl">-->
<!--                    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M180-180h44l443-443-44-44-443 443v44Zm614-486L666-794l42-42q17-17 42-17t42 17l44 44q17 17 17 42t-17 42l-42 42Zm-42 42L248-120H120v-128l504-504 128 128Zm-107-21-22-22 44 44-22-22Z"/></svg>-->
<!--                  </a>-->
<!--                </td>-->
                <!--  履歷筆記閱覽按鈕: 留給下期，暫時註解  -->
<!--                <td v-show="!switchManageAndReadState" class="px-2 py-4 whitespace-nowrap text-right text-sm font-medium">-->
<!--                  <a @click="showNoteImg(content.record_id)" v-if="content.has_content_or_img" href="#" class="text-indigo-600 hover:text-indigo-900 text-2xl">-->
<!--                    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="M100-120q-24.75 0-42.375-17.625T40-180v-560h60v560h740v60H100Zm120-120q-24.75 0-42.375-17.625T160-300v-520q0-24.75 17.625-42.375T220-880h240l80 80h320q24.75 0 42.375 17.625T920-740v440q0 24.75-17.625 42.375T860-240H220Zm114-180h412L614-596 504-450l-74-86-96 116Z"/></svg>-->
<!--                  </a>-->
<!--                </td>-->
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {{ content.record_id }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {{ content.uuid }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {{ content.time }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {{ content.place }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {{ content.operation }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {{ content.detail }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {{ content.product }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {{ content.product_code }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {{ content.value }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {{ content.unit }}&emsp;&emsp;
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {{ content.area }}&emsp;&emsp;
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  <!--  若空值則用\u2003佔位，形同&emsp(因為vuejs會自動轉義，得用代號\u2003  -->
                  {{ content.area_unit ? content.area_unit : '\u2003\u2003\u2003' }}&emsp;
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span v-if="!content.verified" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                    未驗證
                  </span>
                  <span v-else class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    已驗證
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span v-if="!content.error" class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    未註銷
                  </span>
                  <span v-else class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                    已註銷
                  </span>
                </td>
                <td v-show="switchManageAndReadState" class="px-4 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <a @click="switchErrorTag(content.record_id, contentIndex)" v-if="!content.verified" href="#" class="text-indigo-600 hover:text-indigo-900">註銷切換</a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from '../services/user.service'
import Config from '@/config/config'
export default {
  components: {
  },
  name: 'Platform',
  data () {
    return {
      resolveGeneralConfirm: null,
      // 泛用確認操作選單相關ex: note edit/delete for both image and text
      generalConfirm: false,
      showGeneralConfirmDialog: false,
      generalConfirmTemp: false,
      generalConfirmTitle: '',
      // 筆記: 內容與圖片相關
      showNoteDialog: false,
      showEditNoteDialog: false,
      showImageEditDialog: false,
      showContentEditDialog: false,
      selectedImg: null,
      setNoteContent: '',
      // 下面這兩個內容會在兩個情況觸發，read note、edit note都會觸發(編輯也會觸發是因為這樣使用者才能看到舊的耐榮
      currentNoteRecordID: '',
      currentImgURL: '',
      currentNoteContent: '',
      currentIDHasImg: false,
      currentIDHasContent: false,
      // imgKey: '', // 用來強制讓image更新的key
      // 管理閱覽切換與閱覽導航
      // true為管理模式、false為閱覽模式
      switchManageAndReadState: true,
      readActive: false,
      readSelectOptions: [],
      readSelectState: '請選擇',
      // 要有選擇的狀態下才能重新整理該選擇之產品
      reloadReadPageActive: false,
      // 要有選擇狀態下才能下載選擇之產品
      downloadReadPageActive: false,
      // currentReadContentTemp是用來暫存閱覽模式的履歷紀錄(使他可以在管理模式與閱覽模式快速切換)
      currentReadContentTemp: [],
      // 要有選擇狀態下才能顯示碳計算(會用選的產品去計算)
      carbonComputeActive: false,
      showCarbonComputeDialogToDB: false,
      // 用於暫存紀錄管理模式取得之table內容，切回管理時候，可以直接轉換
      currentMangeContentTemp: [],
      // ---------
      // 客製索引取得履歷Excel設置跳窗
      showSetQueryDialogToDB: false,
      setQueryContent: '',
      // 上傳跳窗用途
      showUploadExcelDialogToDB: false,
      selectedFile: null,
      // 提醒跳窗用途
      showNotificationDialog: false,
      formattedMessage: '',
      // ---------
      isLoading: false,
      editForm: false,
      commitVerifyForm: false,
      contents: [],
      nameTemp: '',
      emailTemp: '',
      confirmTemp: false,
      connected: true, // 區塊鏈連線狀態，true是未連線
      // record排序種類: 1為record id排序、2為unix date排序，從bufferDB取來的original狀態是unix date排序
      sortType: 2,
      decrementOrIncrement: true,
      filterActive: false,
      filterList: ['最新時間紀錄', '最末紀錄ID紀錄', '未驗證上鏈紀錄'],
      selectFilterState: '請選擇',
      // 取得的record種類: 設置三種條件0(指定的最新時間紀錄-後端指定數量)、1(指定的最末ID紀錄-後端指定數量)、2(未驗證上鏈紀錄-後端指定數量)
      recordType: 0,
      // 需要呼叫後台，得知所有records有幾筆
      recordAmount: 0,
      // 後台設定，單次能取得的最大紀錄比數(前端也會於config.js中定義一樣數字)
      recordGetMaxAmount: Config.recordGetMaxAmountBasedOnBackendSet,
      recordMaxPage: 0,
      currentRecordPage: 0,
      // 與輸入框綁定的，用來指定獲取records的起始位置
      recordLocationNumber: ''
      // 用來搜尋用途的
      // searchName: ''
    }
  },
  methods: {
    async showEditNote (recordId, hasContentImg) {
      this.currentNoteRecordID = recordId
      this.currentImgURL = ''
      // 筆記書入內容，每次編輯前會清空，以防之前的舊的內容被錯誤送出
      this.setNoteContent = ''
      this.currentNoteContent = ''
      this.selectedImg = null
      this.showEditNoteDialog = true
      this.isLoading = true
      if (hasContentImg) {
        await UserService.GetRecordNoteImgByID(recordId).then(
          res => {
            const result = res.data
            // console.log(result)
            if (result.is_err) {
              this.currentNoteRecordID = ''
              this.setNotificationDialog('錯誤訊息-取得既有履歷筆記失敗: ' + result.err)
            } else {
              // console.log('has圖片:', result.result.has_img)
              if (result.result.has_img) {
                // console.log('取得圖片: check1')
                this.currentImgURL = result.result.image
                // this.currentImgURL = `${result.result.image}?time=${new Date().getTime()}`
              } else {
                // console.log('取得圖片: check2')
                this.currentImgURL = require('@/assets/ch_image_placeholder.png')
              }
              // this.imgKey = new Date().getTime()
              // console.log('has文字:', result.result.has_note)
              if (result.result.has_note) {
                // console.log('取得文字: check1')
                this.currentNoteContent = result.result.note
              } else {
                // console.log('取得文字: check2')
                this.currentNoteContent = '此履歷紀錄筆記，無既有文字筆記內容'
              }
              // 上述內容確認完畢後才會顯示筆記dialog，並關閉讀取等待dialog
              this.isLoading = false
            }
          }
        ).catch(err => {
          // console.log('Auth err')
          // console.log('Auth err')
          if (err.response) {
            // Request made and server responded 請求成立且回應有回來，是錯誤情況
            // 若沒有要鎖住畫面則可以註解或清除掉
            this.setNotificationDialog('取得既有履歷筆記請求有成立且有收到回應，但回應狀態為錯誤情況，可嘗試重新請求或回報給管理人員')
            this.currentNoteRecordID = ''
            this.showNoteDialog = false
            this.isLoading = false
            return false
          } else if (err.request) {
            // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
            this.setNotificationDialog('取得既有履歷筆記請求有成立，但沒有獲得後端伺服回應，可嘗試重新請求或回報給管理人員')
            this.currentNoteRecordID = ''
            this.showNoteDialog = false
            this.isLoading = false
            return false
          } else {
            // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
            this.currentNoteRecordID = ''
            this.setNotificationDialog('取得既有履歷筆記請求建立失敗，可嘗試重新請求或回報給管理人員')
            this.showNoteDialog = false
            this.isLoading = false
            return false
          }
        })
      } else {
        this.currentImgURL = require('@/assets/ch_image_placeholder.png')
        this.currentNoteContent = '此履歷紀錄筆記，無既有文字筆記內容'
        this.isLoading = false
      }
    },
    async showNoteImg (recordId) {
      this.currentNoteRecordID = recordId
      this.currentImgURL = ''
      this.currentNoteContent = ''
      this.isLoading = true
      await UserService.GetRecordNoteImgByID(recordId).then(
        res => {
          const result = res.data
          // console.log(result)
          if (result.is_err) {
            this.currentNoteRecordID = ''
            this.setNotificationDialog('錯誤訊息:' + result.err)
          } else {
            // console.log('has圖片:', result.result.has_img)
            if (result.result.has_img) {
              // console.log('取得圖片: check1')
              this.currentImgURL = result.result.image
              // this.currentImgURL = `${result.result.image}?time=${new Date().getTime()}`
            } else {
              // console.log('取得圖片: check2')
              this.currentImgURL = require('@/assets/ch_image_placeholder.png')
            }
            // this.imgKey = new Date().getTime()
            // console.log('has文字:', result.result.has_note)
            if (result.result.has_note) {
              // console.log('取得文字: check1')
              this.currentNoteContent = result.result.note
            } else {
              // console.log('取得文字: check2')
              this.currentNoteContent = '此履歷紀錄筆記，無文字筆記內容'
            }
            // 上述內容確認完畢後才會顯示筆記dialog，並關閉讀取等待dialog
            this.isLoading = false
            this.showNoteDialog = true
          }
        }
      ).catch(err => {
        // console.log('Auth err')
        // console.log('Auth err')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.setNotificationDialog('履歷筆記請求有成立且有收到回應，但回應狀態為錯誤情況，可嘗試重新請求或回報給管理人員')
          this.currentNoteRecordID = ''
          this.showNoteDialog = false
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.setNotificationDialog('履歷筆記請求有成立，但沒有獲得後端伺服回應，可嘗試重新請求或回報給管理人員')
          this.currentNoteRecordID = ''
          this.showNoteDialog = false
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.currentNoteRecordID = ''
          this.setNotificationDialog('履歷筆記請求建立失敗，可嘗試重新請求或回報給管理人員')
          this.showNoteDialog = false
          this.isLoading = false
          return false
        }
      })
    },
    setNotificationDialog (content) {
      this.showNotificationDialog = true
      this.formattedMessage = content
    },
    // logOutCheck () {
    //   // 這做法應該是可以接受，因為其實操作都是按按鈕，若過期則不給操作，也部會說像是定時一直再做
    //   // 再則是說點擊這頻率也不適說高到很離譜，未來若覺得操作frequency太高，這資源吃太慘，只需要註解此method，與事件
    //   // 定時檢查更新
    //   // ref: https://stackoverflow.com/questions/52836501/make-computed-vue-properties-dependent-on-current-time
    //   // ref: https://www.runoob.com/jsref/met-win-setinterval.html
    //   const loggedIn = localStorage.getItem('user')
    //   const JSONLoggedIn = JSON.parse(loggedIn)
    //   const expireTime = JSONLoggedIn.expireTime
    //   const timeNow = Math.ceil(new Date().getTime() / 1000)
    //   const expireCheck = timeNow - expireTime
    //   console.log('hiSX ' + expireCheck)
    //   // --------------------------------------------------
    //   if (expireCheck > 0) {
    //     console.log('hix')
    //     this.$store.dispatch('auth/logout')
    //     this.$router.push('/login')
    //   }
    // },
    showUploadExcelDialog () {
      // 點進來就重新開始，將之前選的檔案清空
      this.selectedFile = null
      this.showUploadExcelDialogToDB = !this.showUploadExcelDialogToDB
    },
    handleImgUpload (event) {
      this.selectedImg = event.target.files[0]
    },
    handleFileUpload (event) {
      this.selectedFile = event.target.files[0]
    },
    async deleteNoteJpgImgDateById () {
      // promise僅能使用一次，因此要使用前要先建立
      this.waitForGeneralConfirmForImgUpload = new Promise(resolve => {
        this.resolveGeneralConfirm = resolve
      })
      this.showImageEditDialog = false
      this.showGeneralConfirmDialog = true
      this.generalConfirmTitle = '是否刪除ID: ' + this.currentNoteRecordID + ' 履歷筆記之圖片'
      const confirmed = await this.waitForGeneralConfirmForImgUpload
      if (confirmed) {
        // console.log('confirm true')
        this.showGeneralConfirmDialog = false
        this.isLoading = true
        await UserService.DeleteNoteJpgImgByRecordId(this.currentNoteRecordID).then(
          res => {
            const result = res.data
            // console.log(result)
            this.isLoading = false
            if (result.is_err) {
              this.setNotificationDialog('錯誤訊息:' + result.err)
              this.showImageEditDialog = false
            } else {
              this.reloadReadPage()
              this.setNotificationDialog('成功從此履歷紀錄之筆記中清除筆記圖片')
              this.showImageEditDialog = false
            }
          }
        ).catch(
          err => {
            if (err.response) {
              // Request made and server responded 請求成立且回應有回來，是錯誤情況
              // 若沒有要鎖住畫面則可以註解或清除掉
              this.setNotificationDialog('履歷筆記圖片刪除請求有成立且有收到回應，但回應狀態為錯誤情況，請回報給管理人員，勿繼續連續上傳')
              this.showImageEditDialog = false
              this.isLoading = false
              return false
            } else if (err.request) {
              // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
              this.setNotificationDialog('履歷筆記圖片刪除請求請求有成立，但沒有獲得後端伺服回應，請回報給管理人員，勿繼續連續上傳')
              this.showImageEditDialog = false
              this.isLoading = false
              return false
            } else {
              // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
              this.setNotificationDialog('履歷筆記圖片刪除請求請求建立失敗，請回報給管理人員，勿繼續連續上傳')
              this.showImageEditDialog = false
              this.isLoading = false
              return false
            }
          }
        )
      } else {
        // console.log('confirm false')
        this.showGeneralConfirmDialog = false
        this.showImageEditDialog = true
      }
    },
    async deleteNoteTextContentDateById () {
      // promise僅能使用一次，因此要使用前要先建立
      this.waitForGeneralConfirmForImgUpload = new Promise(resolve => {
        this.resolveGeneralConfirm = resolve
      })
      this.showContentEditDialog = false
      this.showGeneralConfirmDialog = true
      this.generalConfirmTitle = '是否刪除ID: ' + this.currentNoteRecordID + ' 履歷筆記之文字'
      const confirmed = await this.waitForGeneralConfirmForImgUpload
      if (confirmed) {
        // console.log('confirm true')
        this.showGeneralConfirmDialog = false
        this.isLoading = true
        await UserService.DeleteNoteTextContent(this.currentNoteRecordID).then(
          res => {
            const result = res.data
            // console.log(result)
            this.isLoading = false
            if (result.is_err) {
              this.setNotificationDialog('錯誤訊息:' + result.err)
              this.showContentEditDialog = false
            } else {
              this.reloadReadPage()
              this.setNotificationDialog('成功從此履歷紀錄之筆記中清除筆記文字')
              this.showContentEditDialog = false
            }
          }
        ).catch(
          err => {
            if (err.response) {
              // Request made and server responded 請求成立且回應有回來，是錯誤情況
              // 若沒有要鎖住畫面則可以註解或清除掉
              this.setNotificationDialog('履歷筆記文字刪除請求有成立且有收到回應，但回應狀態為錯誤情況，請回報給管理人員，勿繼續連續上傳')
              this.showContentEditDialog = false
              this.isLoading = false
              return false
            } else if (err.request) {
              // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
              this.setNotificationDialog('履歷筆記文字刪除請求請求有成立，但沒有獲得後端伺服回應，請回報給管理人員，勿繼續連續上傳')
              this.showContentEditDialog = false
              this.isLoading = false
              return false
            } else {
              // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
              this.setNotificationDialog('履歷筆記文字刪除請求請求建立失敗，請回報給管理人員，勿繼續連續上傳')
              this.showContentEditDialog = false
              this.isLoading = false
              return false
            }
          }
        )
      } else {
        // console.log('confirm false')
        this.showGeneralConfirmDialog = false
        this.showContentEditDialog = true
      }
    },
    async uploadNoteJpgImgData () {
      if (!this.selectedImg) {
        this.setNotificationDialog('當前尚未選擇任何.jpg的圖片檔案檔案，無法進行上傳')
        return
      }
      // promise僅能使用一次，因此要使用前要先建立
      this.waitForGeneralConfirmForImgUpload = new Promise(resolve => {
        this.resolveGeneralConfirm = resolve
      })
      this.showImageEditDialog = false
      this.showGeneralConfirmDialog = true
      this.generalConfirmTitle = '是否上傳更新ID: ' + this.currentNoteRecordID + ' 履歷筆記之圖片'
      const confirmed = await this.waitForGeneralConfirmForImgUpload
      if (confirmed) {
        // console.log('confirm true')
        this.showGeneralConfirmDialog = false
        this.isLoading = true
        await UserService.UploadNoteJpgImg(this.selectedImg, this.currentNoteRecordID).then(
          res => {
            const result = res.data
            // console.log(result)
            this.isLoading = false
            if (result.is_err) {
              this.setNotificationDialog('錯誤訊息:' + result.err)
              this.showImageEditDialog = false
            } else {
              this.reloadReadPage()
              this.setNotificationDialog('此圖片成功更新匯入到此履歷紀錄之筆記中')
              this.showImageEditDialog = false
            }
          }
        ).catch(
          err => {
            if (err.response) {
              // Request made and server responded 請求成立且回應有回來，是錯誤情況
              // 若沒有要鎖住畫面則可以註解或清除掉
              this.setNotificationDialog('履歷筆記圖片上傳更新請求有成立且有收到回應，但回應狀態為錯誤情況，請回報給管理人員，勿繼續連續上傳')
              this.showImageEditDialog = false
              this.isLoading = false
              return false
            } else if (err.request) {
              // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
              this.setNotificationDialog('履歷筆記圖片上傳更新請求有成立，但沒有獲得後端伺服回應，請回報給管理人員，勿繼續連續上傳')
              this.showImageEditDialog = false
              this.isLoading = false
              return false
            } else {
              // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
              this.setNotificationDialog('履歷筆記圖片上傳更新請求建立失敗，請回報給管理人員，勿繼續連續上傳')
              this.showImageEditDialog = false
              this.isLoading = false
              return false
            }
          }
        )
      } else {
        // console.log('confirm false')
        this.showGeneralConfirmDialog = false
        this.showImageEditDialog = true
        this.selectedImg = null
      }
    },
    async uploadNoteTextContentData () {
      if (this.setNoteContent === '') {
        this.setNotificationDialog('當前尚未輸入任何文字筆記內容，無法進行上傳')
        return
      }
      // promise僅能使用一次，因此要使用前要先建立
      this.waitForGeneralConfirmForImgUpload = new Promise(resolve => {
        this.resolveGeneralConfirm = resolve
      })
      this.showImageEditDialog = false
      this.showGeneralConfirmDialog = true
      this.generalConfirmTitle = '是否上傳更新ID: ' + this.currentNoteRecordID + ' 履歷筆記之文字'
      const confirmed = await this.waitForGeneralConfirmForImgUpload
      if (confirmed) {
        // console.log('confirm true')
        this.showGeneralConfirmDialog = false
        this.isLoading = true
        await UserService.UploadNoteTextContent(this.currentNoteRecordID, this.setNoteContent).then(
          res => {
            const result = res.data
            // console.log(result)
            this.isLoading = false
            if (result.is_err) {
              this.setNotificationDialog('錯誤訊息:' + result.err)
              this.showContentEditDialog = false
            } else {
              this.reloadReadPage()
              this.setNotificationDialog('此文字成功更新匯入到此履歷紀錄之筆記中')
              this.showContentEditDialog = false
            }
          }
        ).catch(
          err => {
            if (err.response) {
              // Request made and server responded 請求成立且回應有回來，是錯誤情況
              // 若沒有要鎖住畫面則可以註解或清除掉
              this.setNotificationDialog('履歷筆記文字上傳更新請求有成立且有收到回應，但回應狀態為錯誤情況，請回報給管理人員，勿繼續連續上傳')
              this.showContentEditDialog = false
              this.isLoading = false
              return false
            } else if (err.request) {
              // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
              this.setNotificationDialog('履歷筆記文字上傳更新請求有成立，但沒有獲得後端伺服回應，請回報給管理人員，勿繼續連續上傳')
              this.showContentEditDialog = false
              this.isLoading = false
              return false
            } else {
              // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
              this.setNotificationDialog('履歷筆記文字上傳更新請求建立失敗，請回報給管理人員，勿繼續連續上傳')
              this.showContentEditDialog = false
              this.isLoading = false
              return false
            }
          }
        )
      } else {
        // console.log('confirm false')
        this.showGeneralConfirmDialog = false
        this.showContentEditDialog = true
        // 文字輸入比較有可能想一想回來重新編輯(跟圖片不同，因此不設置為'')
        // this.setNoteContent = ''
      }
    },
    async uploadExcelData () {
      if (!this.selectedFile) {
        this.setNotificationDialog('當前尚未選擇任何.xlsx的Excel檔案，無法進行上傳')
        return // 如果没有選擇檔案，則直接返回沒有作用
      }
      // this.setNotificationDialog('當前有選擇')
      this.isLoading = true
      await UserService.UploadExcel(this.selectedFile).then(
        res => {
          const result = res.data
          // console.log(result)
          this.isLoading = false
          if (result.isWorking) {
            this.setNotificationDialog('有其他批次作業運作中，為防止檔案重複，請待其他作業完成再進行批次匯入')
            this.showUploadExcelDialogToDB = false
          } else if (result.is_err) {
            this.setNotificationDialog('錯誤訊息:' + result.err)
            this.showUploadExcelDialogToDB = false
          } else {
            this.setNotificationDialog('此Excel履歷紀錄成功批次匯入')
            this.showUploadExcelDialogToDB = false
          }
        }
      ).catch(err => {
        // console.log('Auth err')
        // console.log('Auth err')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.setNotificationDialog('上傳請求有成立且有收到回應，但回應狀態為錯誤情況，請回報給管理人員，勿繼續連續上傳')
          this.showUploadExcelDialogToDB = false
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.setNotificationDialog('上傳請求有成立，但沒有獲得後端伺服回應，請回報給管理人員，勿繼續連續上傳')
          this.showUploadExcelDialogToDB = false
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.setNotificationDialog('上傳請求建立失敗，請回報給管理人員，勿繼續連續上傳')
          this.showUploadExcelDialogToDB = false
          this.isLoading = false
          return false
        }
      })
    },
    async downLoadExcelData () {
      this.isLoading = true
      await UserService.getRecordInExcel().then(
        res => {
          // 下面這個將回傳的response轉換成URL，將response的檔案數據以Blob(這可以承接檔案或者二進檔案)
          // 要設置正確MME type: browser才能呼叫正確app來開啟(開啟app也能配合知道是什麼格式，相容性才會好)，後面charset則是設置utf-8編碼(一般這個編碼較不會出錯)
          // 若採用的是.xls則是另一種MME，有需要可以去查詢使用
          const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' })
          // 然後生成一個a 連結元素
          const link = document.createElement('a')
          // 將之前的url注入給此連結元素
          link.href = url
          // 設定此連結屬性，為下載以及值(下載名稱)
          link.setAttribute('download', 'all_result.xlsx')
          // 接著將上面處理好的原件append到body之中，並且模仿點擊事件觸發這個下載動作
          document.body.appendChild(link)
          link.click()
          // 使用完畢將link <a>清除，並且結束鎖住畫面
          document.body.removeChild(link)
          this.isLoading = false
        }
      ).catch(err => {
        // console.log('Auth err')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
    },
    async downLoadExcelDataWithProduct (product) {
      this.isLoading = true
      await UserService.getRecordByProductInExcel(product).then(
        res => {
          // 下面這個將回傳的response轉換成URL，將response的檔案數據以Blob(這可以承接檔案或者二進檔案)
          // 要設置正確MME type: browser才能呼叫正確app來開啟(開啟app也能配合知道是什麼格式，相容性才會好)，後面charset則是設置utf-8編碼(一般這個編碼較不會出錯)
          // 若採用的是.xls則是另一種MME，有需要可以去查詢使用
          const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' })
          // 然後生成一個a 連結元素
          const link = document.createElement('a')
          // 將之前的url注入給此連結元素
          link.href = url
          // 設定此連結屬性，為下載以及值(下載名稱)
          link.setAttribute('download', product + '_results.xlsx')
          // 接著將上面處理好的原件append到body之中，並且模仿點擊事件觸發這個下載動作
          document.body.appendChild(link)
          link.click()
          // 使用完畢將link <a>清除，並且結束鎖住畫面
          document.body.removeChild(link)
          this.isLoading = false
        }
      ).catch(err => {
        // console.log('Auth err')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
    },
    async downLoadExcelDataWithGPT () {
      this.isLoading = true
      await UserService.getRecordInExcelWithGPT(this.setQueryContent).then(
        res => {
          // 下面這個將回傳的response轉換成URL，將response的檔案數據以Blob(這可以承接檔案或者二進檔案)
          // 要設置正確MME type: browser才能呼叫正確app來開啟(開啟app也能配合知道是什麼格式，相容性才會好)，後面charset則是設置utf-8編碼(一般這個編碼較不會出錯)
          // 若採用的是.xls則是另一種MME，有需要可以去查詢使用
          const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' })
          // 然後生成一個a 連結元素
          const link = document.createElement('a')
          // 將之前的url注入給此連結元素
          link.href = url
          // 設定此連結屬性，為下載以及值(下載名稱)
          link.setAttribute('download', 'specific_result.xlsx')
          // 接著將上面處理好的原件append到body之中，並且模仿點擊事件觸發這個下載動作
          document.body.appendChild(link)
          link.click()
          // 使用完畢將link <a>清除，並且結束鎖住畫面
          document.body.removeChild(link)
          this.showSetQueryDialogToDB = false
          this.isLoading = false
        }
      ).catch(err => {
        // console.log('Auth err')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.showSetQueryDialogToDB = false
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.showSetQueryDialogToDB = false
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.showSetQueryDialogToDB = false
          this.isLoading = false
          return false
        }
      })
    },
    // specifyRecordLocation: arrow用來判定是首頁0/下頁1/上頁-1，來調整指定位置this.currentRecordPage
    async specifyRecordLocation (arrow) {
      let pageCountTemp
      pageCountTemp = this.currentRecordPage
      if (arrow === 0) {
        pageCountTemp = 1
        // 選擇的模式切換為初始
        this.selectFilterState = '請選擇'
      } else if (arrow === 1 && pageCountTemp < this.recordMaxPage) {
        pageCountTemp += 1
        // 選擇的模式切換為初始
        this.selectFilterState = '請選擇'
      } else if (arrow === -1 && pageCountTemp > 1) {
        pageCountTemp -= 1
        // 選擇的模式切換為初始，但若是譬如點上頁，但本身是0，那形同沒切換，就不初始化
        if (pageCountTemp !== 0) {
          this.selectFilterState = '請選擇'
        }
      } else if (arrow === this.recordMaxPage) {
        pageCountTemp = this.recordMaxPage
      } else {
        return
      }
      // this.recordLocationNumber 原本是使用者指派數值於input透過此data
      if (pageCountTemp <= this.recordMaxPage || pageCountTemp >= 1) {
        this.isLoading = true
        // record排序種類: 1為record id排序、2為unix date排序，從bufferDB取來的original狀態是unix date排序
        // 採1因為這個是採record id排序，才能符合offset偏移索引越大越就是越後面產生的records
        this.sortType = 1
        // 切換為遞減(大到小)
        this.decrementOrIncrement = true
        this.currentRecordPage = pageCountTemp
        const offset = ((this.currentRecordPage - 1) * this.recordGetMaxAmount) + 1
        await UserService.getAuthRecordContentByNumber(offset).then(
          res => {
            this.contents = res.data.result
            this.isLoading = false
            // console.log(this.contents)
            // 之後要改成取得資料就關閉，現在是測試
            // setTimeout(() => { this.isLoading = false }, 1200)
          }
        ).catch(err => {
          // console.log('Auth err')
          if (err.response) {
            // Request made and server responded 請求成立且回應有回來，是錯誤情況
            // 若沒有要鎖住畫面則可以註解或清除掉
            this.isLoading = false
            return false
          } else if (err.request) {
            // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
            this.isLoading = false
            return false
          } else {
            // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
            this.isLoading = false
            return false
          }
        })
      }
    },
    async readSelectEvent () {
      const product = event.target.innerText
      // 下面這個是點擊縮、放選單
      this.readActive = !this.readActive
      // 選後把把選擇狀態顯示出來
      this.readSelectState = event.target.innerText
      this.reloadReadPageActive = true
      this.downloadReadPageActive = true
      this.carbonComputeActive = true
      this.isLoading = true
      await UserService.GetDetailRecordByProductWithAuth(product).then(
        res => {
          const result = res.data
          if (result.is_err) {
            this.isLoading = false
            this.setNotificationDialog('無法取得指定產品之履歷紀錄，若無法排除將下列錯誤代號回報: ' + result.err)
          } else {
            this.currentReadContentTemp = result.result
            this.contents = result.result
            this.isLoading = false
          }
        }
      ).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          this.setNotificationDialog('指定產品之履歷紀錄請求成功，但收到錯誤之回應')
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('指定產品之履歷紀錄請求成功，但無法取得服務回應')
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('指定產品之履歷紀錄請求服務無法建立')
          return false
        }
      })
    },
    async selectFilterEvent (filter) {
      this.isLoading = true
      // 下面這個是點擊縮、放選單
      this.filterActive = !this.filterActive
      // 選後把把選擇狀態顯示出來
      this.selectFilterState = event.target.innerText
      // 重新整理也順便將順序閱覽調至0，表示當前並非用順序閱覽
      this.currentRecordPage = 0
      // Product code選擇欄位初始化
      if (this.selectFilterState === '最新時間紀錄') {
        this.sortType = 2
        this.decrementOrIncrement = true
        this.recordType = 0
      } else if (this.selectFilterState === '最末紀錄ID紀錄') {
        this.sortType = 1
        this.decrementOrIncrement = true
        this.recordType = 1
      } else if (this.selectFilterState === '未驗證上鏈紀錄') {
        this.sortType = 2
        this.decrementOrIncrement = true
        this.recordType = 2
      } else {
        this.sortType = 2
        this.decrementOrIncrement = true
        // 其他情形或特例都是設置0
        this.recordType = 0
      }
      await UserService.getAuthRecordContent(this.recordType).then(
        res => {
          this.contents = res.data.result
          this.isLoading = false
          // console.log(this.contents)
          // 之後要改成取得資料就關閉，現在是測試
          // setTimeout(() => { this.isLoading = false }, 1200)
        }
      ).catch(err => {
        // console.log('Auth err')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
    },
    sortSwitch (dateOrID) {
      if (dateOrID === 1) {
        this.sortType = 1
        this.decrementOrIncrement = !this.decrementOrIncrement
      } else if (dateOrID === 2) {
        this.sortType = 2
        this.decrementOrIncrement = !this.decrementOrIncrement
      }
    },
    async getRecordFromAPP () {
      this.isLoading = true
      await UserService.GetRecordFromApp().then(
        res => {
          // 他只是跟你說成功達成，這邊不需要做後面這段取此內容，this.contents = res.data.result
          // 紀錄實際上取得是從buffer DB，取得紀錄實際上是從this.reloadPage()
        }
      ).catch(err => {
        // // console.log('get records from app error')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
      await this.reloadPage()
      this.isLoading = false
    },
    edit (person) {
      this.nameTemp = person.name
      this.emailTemp = person.email
      this.editForm = !this.editForm
    },
    async submitEdit () {
      // const dataForm = new FormData()
      // 會建立一個const去承接然後遞送出去到後端API然後修改SQL column內容，配合SQL alert語法
      const name = this.nameTemp
      const email = this.emailTemp
      await this.confirmEdit(name, email)
      if (this.confirmTemp) {
        // console.log('Confirmed')
      } else {
        // console.log('Not confirmed')
      }
      this.confirmTemp = false
      this.editForm = !this.editForm
    },
    cancelEdit () {
      this.editForm = false
    },
    async confirmEdit (name, email) {
      await this.$confirm('Are you sure?').then(() => {
        // do something...
        // console.log('Sending edit record to serve')
        // console.log(name, email)
        this.confirmTemp = true
      }).catch(() => {
        this.confirmTemp = false
      })
    },
    // 這個是泛用的用於note edit/delete for both image and text
    checkGeneralConfirmState (confirmState) {
      if (confirmState) {
        this.generalConfirmTemp = true
        if (this.resolveGeneralConfirm) {
          this.resolveGeneralConfirm(this.generalConfirmTemp)
        }
      } else {
        this.generalConfirmTemp = false
        if (this.resolveGeneralConfirm) {
          this.resolveGeneralConfirm(this.generalConfirmTemp)
        }
      }
    },
    async checkConnection () {
      this.isLoading = true
      await UserService.checkConnection().then(
        res => {
          this.connected = res.data.result.err
        }
      ).catch(err => {
        // console.log('Connection err')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
      this.isLoading = false
    },
    async switchErrorTag (recordId, contentIndex) {
      this.isLoading = true
      await UserService.switchErrorTag(recordId).then(
        res => {
          this.isLoading = false
          if (!res.data.is_err) {
            this.contents[contentIndex].error = !this.contents[contentIndex].error
            this.setNotificationDialog(res.data.result)
          } else {
            this.setNotificationDialog(res.data.err)
          }
          // UserService.getAuthRecordContent(this.recordType).then(
          //   res => {
          //     this.contents = res.data.result
          //     // console.log(this.contents)
          //     // 之後要改成取得資料就關閉，現在是測試
          //     // setTimeout(() => { this.isLoading = false }, 1200)
          //     // this.isLoading = false
          //   }
          // ).catch(err => {
          //   // console.log('Auth err')
          //   if (err.response) {
          //     // Request made and server responded 請求成立且回應有回來，是錯誤情況
          //     // 若沒有要鎖住畫面則可以註解或清除掉
          //     this.isLoading = false
          //     return false
          //   } else if (err.request) {
          //     // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          //     this.isLoading = false
          //     return false
          //   } else {
          //     // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          //     this.isLoading = false
          //     return false
          //   }
          // })
        }
      ).catch(err => {
        // console.log('Switching err')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          this.setNotificationDialog('錯誤-指定履歷之註銷狀態轉換服務請求成功，但回應內容有誤')
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('錯誤-指定履歷之註銷狀態轉換服務請求成功，但卻沒有收到回應')
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('錯誤-指定履歷之註銷狀態轉換服務請求建立失敗')
          return false
        }
      })
    },
    // 這func會把app掃描的record抓進bufferDB並且重新整理資料
    // addNewRecordFromApp () {
    //     this.isLoading = true
    //     UserService.addAppRecordToBufferDB().then(
    //       this.contents = res.d
    //     ).catch(
    //
    //     )
    // },
    async reloadReadPage () {
      this.isLoading = true
      // 直接用當前選擇的readSelectState作為product
      await UserService.GetDetailRecordByProductWithAuth(this.readSelectState).then(
        res => {
          const result = res.data
          if (result.is_err) {
            this.isLoading = false
            this.setNotificationDialog('無法取得指定產品之履歷紀錄，若無法排除將下列錯誤代號回報: ' + result.err)
          } else {
            this.currentReadContentTemp = result.result
            this.contents = result.result
            this.isLoading = false
          }
        }
      ).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          this.setNotificationDialog('指定產品之履歷紀錄請求成功，但收到錯誤之回應')
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('指定產品之履歷紀錄請求成功，但無法取得服務回應')
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('指定產品之履歷紀錄請求服務無法建立')
          return false
        }
      })
    },
    async reloadPage () {
      this.isLoading = true
      // 重新整理也順便將順序閱覽調至0，表示當前並非用順序閱覽
      this.currentRecordPage = 0
      this.selectFilterState = '最末紀錄ID紀錄'
      // 重新整理的抓資料
      // 初始化取紀錄是以最末ID排序，因此設置1，因為不論app產生還是、批次匯入都是ID越後面越後產生
      await UserService.getAuthRecordContent(1).then(
        res => {
          this.contents = res.data.result
          // 之後要改成取得資料就關閉，現在是測試
          // setTimeout(() => { this.isLoading = false }, 1200)
        }
      ).catch(err => {
        // console.log('Auth err')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
      await UserService.getAuthRecordAmount().then(
        res => {
          this.recordAmount = res.data.result
          this.recordMaxPage = Math.ceil(this.recordAmount / this.recordGetMaxAmount)
        }
      ).catch(err => {
        // console.log('Connection err')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
      this.isLoading = false
    },
    async reCheckPage () {
      this.isLoading = true
      // 重新整理的抓資料
      await UserService.RecheckRecordVerified().then(
        res => {
          // 他只是跟你說成功達成，這邊不需要做後面這段取此內容，this.contents = res.data.result
          // 紀錄實際上取得是從buffer DB，取得紀錄實際上是從this.reloadPage()
          if (res.data.is_err) {
            this.isLoading = false
            this.setNotificationDialog('履歷紀錄上鏈狀態更新驗證失敗，可回報此錯誤訊息代號' + res.data.err)
          } else {
            this.isLoading = false
            this.setNotificationDialog('履歷紀錄上鏈狀態更新驗證成功，目前已更新成最新上鏈狀態: ' + res.data.result)
          }
          this.reloadPage()
        }
      ).catch(err => {
        // console.log('Auth err')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          this.setNotificationDialog('履歷紀錄上鏈狀態更新驗證請求成功，但收到錯誤之回應')
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('履歷紀錄上鏈狀態更新驗證請求成功，無法取得服務回應，可能上鏈數量多造成，可過一陣子先進行驗證確認或再執行上鏈動作(將未上鏈內容繼續進行上鏈')
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('履歷紀錄上鏈狀態更新驗證請求服務無法建立')
          return false
        }
      })
    },
    // 這個是跳出要送出記錄到區塊鏈的視窗
    async commitVerify () {
      this.isLoading = true
      await UserService.CheckAddBlockchainStatus().then(
        res => {
          if (res.data.status) {
            this.isLoading = false
            this.setNotificationDialog('後端正在進行履歷紀錄上鏈工作，有可能會需要一段較長的時間，建議當前不要做其他任何操作與請求')
          } else {
            this.isLoading = false
            // this.setNotificationDialog('目前後端沒有進行履歷紀錄上鏈工作，可以進行上鏈或者其他系統操作與請求')
            this.commitVerifyForm = true
          }
        }
      ).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          this.setNotificationDialog('確認後端是否正在進行上鏈操作狀態之請求成功，但收到錯誤之回應')
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('確認後端是否正在進行上鏈操作狀態之請求成功，但無法取得服務回應')
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('確認後端是否正在進行上鏈操作狀態之請求服務無法建立')
          return false
        }
      })
    },
    // 這個是要實際發送記錄到區塊鏈的操作
    async commitRecords () {
      this.commitVerifyForm = false
      this.isLoading = true
      // console.log('loading ', this.isLoading)
      await UserService.SendBufferRecordToBlockchain().then(
        res => {
          // console.log(this.contents)
          // 之後要改成取得資料就關閉，現在是測試
          // setTimeout(() => { this.isLoading = false }, 1200)
          if (res.data.is_err) {
            this.isLoading = false
            this.setNotificationDialog('上鏈出現錯誤，可能造成紀錄上鏈失敗或部分，可嘗試點選驗證確認看觀測上鏈狀態，並回報此錯誤訊息代號: ' + res.data.err)
          } else {
            this.isLoading = false
            this.setNotificationDialog('上鏈成功，可嘗試點選驗證確認來觀看最新的紀錄上鏈狀態: ' + res.data.result)
          }
          this.reloadPage()
        }
      ).catch(err => {
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          this.setNotificationDialog('履歷紀錄上鏈請求成功，但收到錯誤之回應')
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('履歷紀錄上鏈請求成功，但無法取得服務回應，有可能是資料繁多仍在後端處理，可以再點上鏈按鈕觀看後台上鏈之狀態')
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          this.setNotificationDialog('履歷紀錄上鏈請求服務無法建立')
          return false
        }
      })
    },
    async cancelCommitVerify () {
      this.commitVerifyForm = false
    },
    async confirmCommit () {
      await this.$confirm('確定要執行此操作?').then(async () => {
        // do something...
        // console.log('Yes selected')
        this.confirmTemp = true
      }).catch(() => {
        // console.log('No selected')
        this.confirmTemp = false
      })
    }
  },
  async mounted () {
    // 初期要先將檔案選取清空，否則可能會留有之前的選擇
    this.selectedFile = null
    this.setNotificationDialog('頁面開始採最末ID的幾筆紀錄，順序閱覽是ID小至大(初始0頁，表當前未採順序瀏覽)')
    this.isLoading = true
    // 剛進入頁面順序閱覽調至0，表示當前並非用順序閱覽
    this.currentRecordPage = 0
    this.sortType = 2
    this.decrementOrIncrement = true
    // 初始化取紀錄是以最末ID排序，因此設置1，因為不論app產生還是、批次匯入都是ID越後面越後產生
    this.recordType = 1
    // 初始是以ID排序，但為了讓使用者可以知道可以選，所以初始是設置成"請選擇"
    this.selectFilterState = '請選擇'
    // this.recordAmount = Math.floor(Math.random() * (99 + 1)) + 1
    // 要寫重新整理的抓資料
    // 初始化取紀錄是以最末ID排序，因此設置1，因為不論app產生還是、批次匯入都是ID越後面越後產生
    await UserService.getAuthRecordContent(this.recordType).then(
      res => {
        this.contents = res.data.result
        // console.log(this.contents)
        // 之後要改成取得資料就關閉，現在是測試
        // setTimeout(() => { this.isLoading = false }, 1200)
      }
    ).catch(err => {
      // console.log('Auth err')
      if (err.response) {
        // Request made and server responded 請求成立且回應有回來，是錯誤情況
        // 若沒有要鎖住畫面則可以註解或清除掉
        this.isLoading = false
        return false
      } else if (err.request) {
        // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
        this.isLoading = false
        return false
      } else {
        // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
        this.isLoading = false
        return false
      }
    })
    await UserService.checkConnection().then(
      res => {
        // console.log(this.content.err)
        this.connected = res.data.result.err
      }
    ).catch(err => {
      // console.log('Connection err')
      if (err.response) {
        // Request made and server responded 請求成立且回應有回來，是錯誤情況
        // 若沒有要鎖住畫面則可以註解或清除掉
        this.isLoading = false
        return false
      } else if (err.request) {
        // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
        this.isLoading = false
        return false
      } else {
        // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
        this.isLoading = false
        return false
      }
    })
    await UserService.getAuthRecordAmount().then(
      res => {
        this.recordAmount = res.data.result
        this.recordMaxPage = Math.ceil(this.recordAmount / this.recordGetMaxAmount)
      }
    ).catch(err => {
      // console.log('Connection err')
      if (err.response) {
        // Request made and server responded 請求成立且回應有回來，是錯誤情況
        // 若沒有要鎖住畫面則可以註解或清除掉
        this.isLoading = false
        return false
      } else if (err.request) {
        // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
        this.isLoading = false
        return false
      } else {
        // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
        this.isLoading = false
        return false
      }
    })
    await UserService.GetProductList().then(
      res => {
        this.readSelectOptions = res.data.result
      }
    ).catch(err => {
      // console.log('Connection err')
      if (err.response) {
        // Request made and server responded 請求成立且回應有回來，是錯誤情況
        // 若沒有要鎖住畫面則可以註解或清除掉
        this.isLoading = false
        return false
      } else if (err.request) {
        // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
        this.isLoading = false
        return false
      } else {
        // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
        this.isLoading = false
        return false
      }
    })
    this.isLoading = false
  },
  computed: {
    contentsFilterAndSortedContentsByUnixDate () {
      // 取得相依的data，這樣只要這些內容產生變化就會觸發computed method
      const { contents, sortType, decrementOrIncrement } = this
      // 如果 contents 為 null，直接返回 null，不做任何排序
      if (!contents) {
        return null
      }
      // sortType為1採record ID排序、為2採unix date排序
      if (sortType === 1 && decrementOrIncrement === true) {
        return contents.sort(sortRecordsByRecordIDDecrement)
      } else if (sortType === 1 && decrementOrIncrement === false) {
        return contents.sort(sortRecordsByRecordIDIncrement)
      } else if (sortType === 2 && decrementOrIncrement === true) {
        return contents.sort(sortRecordsByUnixDateDecrement)
      } else {
        return contents.sort(sortRecordsByUnixDateIncrement)
      }
    }
  }
}
function sortRecordsByUnixDateDecrement (a, b) {
  const x = a.unix
  const y = b.unix
  return ((x > y) ? -1 : ((x < y) ? 1 : 0))
}
function sortRecordsByRecordIDDecrement (a, b) {
  const x = a.record_id
  const y = b.record_id
  return ((x > y) ? -1 : ((x < y) ? 1 : 0))
}
function sortRecordsByUnixDateIncrement (a, b) {
  const x = a.unix
  const y = b.unix
  return ((x < y) ? -1 : ((x > y) ? 1 : 0))
}
function sortRecordsByRecordIDIncrement (a, b) {
  const x = a.record_id
  const y = b.record_id
  return ((x < y) ? -1 : ((x > y) ? 1 : 0))
}
</script>

<style scoped>
/* table底色，更重要的是高度，限制畫面一次顯示的紀錄筆數，會影響scrollbar獲取的間距 */
.custom-table-inside {
  background-color: #8b8b8b;
  height: 500px;
}

.custom-note-dialog-h {
  height: 600px;
  width: 700px;
}

.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* class scrollbar背景設置 */
.tableScrollbar::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  /*底色*/
  background: #F5F5F5;
  /*漸層變化*/
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
/* class scrollbar滑動controller設置 */
.tableScrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 150px;
  background-color: #413960;
}
/* class scrollbar corner設置(上下與左右滑動條會有個交錯正方形空白，設置這個才能處理 */
.tableScrollbar::-webkit-scrollbar-corner{
  background: #F5F5F5;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
/* class scrollbar使用提示，使用拉動時候會變色 */
.tableScrollbar::-webkit-scrollbar-thumb:hover {
  background: #85d0a0;
}
</style>
